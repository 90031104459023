import axios from 'axios';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useEffect, useRef, useState } from 'react';

const accessToken: string = process.env.REACT_APP_MAP_BOX_ACCESS_TOKEN ?? '';

const MapBoxMap = (props: any) => {

    const { id, locations } = props;
    const mapContainer = useRef<HTMLDivElement>(null);
    const [markers, setMarkers] = useState<any[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            const data: any = [];
            let counter = 0;
            // @ts-ignore
            const newStates = [...new Set(locations ?? [])]
            for (let indLocation of newStates) {
                if (counter > 100) {
                    break;
                }
                counter++;
                try {
                    const mapboxAccessToken = accessToken;
                    const mapboxGeocodingApi = 'https://api.mapbox.com/geocoding/v5/mapbox.places/';

                    const response = await axios.get(
                        `${mapboxGeocodingApi}${encodeURIComponent(indLocation)}.json?access_token=${mapboxAccessToken}`
                    );

                    const features = response.data.features;

                    if (features.length > 0) {
                        const coordinates = features?.[0]?.center ?? '';
                        data.push(coordinates);
                        setMarkers([...markers, coordinates]);
                    } else {
                        console.log('No coordinates found for the given location.');
                    }
                } catch (error) {
                    console.error('Error fetching coordinates:', error);
                }
            }
            return data;
        }
        if (locations && locations.length) {
            fetchData().then(res => {
                mapboxgl.accessToken = accessToken;
                const map = new mapboxgl.Map({
                    container: mapContainer.current!,
                    style: 'mapbox://styles/mapbox/streets-v11',
                    center: [0, 0],
                    zoom: 1,
                });
                res.forEach((item: any) => {
                    new mapboxgl.Marker()
                        .setLngLat([item?.[0] ?? 0, item?.[1] ?? 0])
                        .addTo(map);
                });
                return () => { map.remove() }
            })
        }

    }, [locations]);


    // useEffect(() => {
    //     mapboxgl.accessToken = accessToken;
    //     const coordinates = [
    //         { id: 1, longitude: -122.4194, latitude: 37.7749 },
    //         { id: 2, longitude: -73.9352, latitude: 40.7306 },
    //         // Add more coordinates as needed
    //     ];
    //     const map = new mapboxgl.Map({
    //         container: mapContainer.current!,
    //         style: 'mapbox://styles/mapbox/streets-v11',
    //         center: [0, 0],
    //         zoom: 1,
    //     });

    //     setCurrentMap(map);

    //     // if (markers && markers.length > 0) {
    //     //     markers.forEach((coord) => {
    //     //         new mapboxgl.Marker()
    //     //             .setLngLat([coord[0], coord[1]])
    //     //             .addTo(map);
    //     //     });
    //     // }


    //     return () => map.remove(); // Cleanup on unmount
    // }, []);

    return <div ref={mapContainer} id={id} />;
};

export default MapBoxMap;
