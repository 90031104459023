import React from 'react';
import './loader.scss'; // Add some styling for the loader (see below)

interface LoaderProps {
    message?: string;
}

const Loader: React.FC<LoaderProps> = ({ message }) => {
    return (
        <div className="loader-container">
            <div className="loader">
                <div className="spinner"></div>
                {message && <p className="loader-message">{message}</p>}
            </div>
        </div>
    );
};

export default Loader;
