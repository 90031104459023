import React, { ReactElement, useEffect, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';

import Back from '../../assets/icon/pataint/arrowback.svg';

interface IProps {
  authType: string,
  emailId: string,
  onClickBack: (type: string, value: string,) => void,
  onSubmit: () => void,
}

const OTPModal = (props: IProps): ReactElement => {
  const { authType, emailId, onSubmit, onClickBack } = props;

  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const [otpValues, setOTPValues] = useState(['', '', '', '']);
  const [isValid, setIsValid] = useState<boolean>(true);
  const [minutes, setMinutes] = useState<number>(1);
  const [seconds, setSeconds] = useState<number>(0);

  let interval: any;

  useEffect(() => {
    interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  const resendOTP = () => {
    setMinutes(1);
    setSeconds(0);
  };

  const handleInputChange = (index: number, value: string) => {
    if (/^\d*$/.test(value) && value.length <= 1) {
      const updatedOTPValues = [...otpValues];
      updatedOTPValues[index] = value;
      setOTPValues(updatedOTPValues);

      if (value && index < otpValues.length - 1) {
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  const onSubmitOTP = (): void => {
    const enteredOTP = otpValues.join('');
    const isValidOtp = enteredOTP.length === 4;

    if (isValidOtp) {
      // sessionStorage.setItem('user-token', '1');
      // navigate('/clinical-trials');
      onSubmit();
    } else {
      setIsValid(false);
    }
  };

  return (
    <Modal size="lg" show={true}>
     <div className="background-left-bottom"/>
      <div className="background-right-top"/>
      <Modal.Header>
        <div
          className="cursor-pointer"
          onClick={() => {
            onClickBack(authType, emailId);
            clearInterval(interval);
          }}
        >
          <img src={Back} alt="back"/>
          <span className="modal-back">Back</span>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-12 text-center">
            <p className="modal-title">Enter OTP</p>
            <p className="modal-sub-title">We have sent a 4 digit OTP to {emailId}</p>

            <div className="d-flex justify-content-center mt-5">
              {otpValues.map((value, index) => (
                <input
                  key={index}
                  type="text"
                  className="form-control no-outline mx-1 text-center otp-input no-outline"
                  style={{
                    width: '48px',
                    height: '48px',
                    border: 'none', // Remove default border
                    borderBottom: '2px solid #000', // Apply underline style
                    borderRadius: '0', // Optional: Remove border radius if needed
                    outline: 'none'
                  }}
                  value={value}
                  maxLength={1}
                  onChange={(e) => handleInputChange(index, e.target.value)}
                  ref={(input) => {
                    inputRefs.current[index] = input;
                  }}
                />
              ))}
            </div>
            {
              !isValid
                ? <p className="text-danger mt-2">Please enter OTP</p>
                : null
            }
            <button
              className="button btn-bg mt-4 border-0 fw-bold rounded d-block w-100"
              onClick={() => onSubmitOTP()}
            >
              Submit OTP
            </button>
            <p className="modal-text mt-3">
              {seconds > 0 || minutes > 0 ? (
                <p>
                  Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                  {seconds < 10 ? `0${seconds}` : seconds}
                </p>
              ) : (
                <p className="text-primary cursor-pointer"
                   onClick={resendOTP}>Resend OTP</p>
              )}
            </p>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default OTPModal;
