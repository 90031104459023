import React, { ReactElement } from 'react';

import { ReactComponent as ClinicalTrialLogo } from '../../assets/icon/clinicalTrial/clinicalTrailLogo.svg';

interface IProps {
  onClickLogin: () => void,
}

const Header = (props: IProps): ReactElement => {
  const { onClickLogin } = props;

  return (
    <header className={'container-fluid header py-3 px-4 mb-2'}>
      <div className="navbar-expand-lg navbar-light">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <ClinicalTrialLogo/>
            <p className="clinical-logo m-0 ps-2">Ctrials</p>
            <span
              className="font-size-18"
              style={{
                fontFamily: 'Poppins-Semi-Bold',
              }}
            >
              .ai
            </span>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <button
              className={'px-5 py-2 rounded-2 border-0 background-color'}
              // onClick={() => navigate("/login")}
              data-bs-toggle="modal"
              data-bs-target="#create-account-modal"
              onClick={() => onClickLogin()}
            >
              <span
                className="text-white font-size-16"
                style={{ fontFamily: 'Poppins-Semi-Bold' }}
              >
                Login
              </span>
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
