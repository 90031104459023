//import React Libraries
import React, { Fragment, useEffect, useState } from "react";
import ChatContainerScreen from "./ChatContainer/ChatContainerScreen";
import { randomNameGenerator } from "../../utils/utility";
import { createNewChat, showAllChatsByUser } from "../../api-services/apis";
import { ReactComponent as Chart } from '../../assets/icon/clinicalTrial/Chart.svg';
import { ReactComponent as SparkBlue } from "../../assets/icon/landing_dashboard/sparkling-fill-blue.svg";
import { ReactComponent as Vector } from "../../assets/icon/clinicalTrial/Vector.svg";
import { ReactComponent as Add } from "../../assets/icon/pataint/plus.svg";

interface IndividualMessage {
  type: string;
  message: string;
  timestamp: number;
}
interface IndividualChat {
  id?: string;
  userID: string;
  title: string;
  end: string;
  history: IndividualMessage[]
}


interface ChatTypeComponent {
  defaultClinicalTrialList: any;
  setClinicalTrialResult: any;
  currentQuestion: any;
  setCurrentQuestion: any;
}


const ChatType: React.FC<ChatTypeComponent> = ({ defaultClinicalTrialList, setClinicalTrialResult, currentQuestion, setCurrentQuestion }) => {
  const [chats, setChats] = useState([]);

  //Intialize state
  const [openChatBox, setOpenChatBox] = useState(true);
  const [individualChat, setIndividualChat] = useState<IndividualChat>();

  const onClickHandler = (chat?: IndividualChat) => {
    setOpenChatBox(!openChatBox);
    setIndividualChat(chat);
  };

  const newChatHandler = () => {
    const userID = localStorage?.getItem('userID') ?? '';
    const newChat: IndividualChat = {
      userID,
      title: "New Chat",
      end: new Date().toDateString(),
      history: [
        {
          type: "BOT",
          message: "Hi there! I'm here to help.",
          timestamp: Date.now(),
        }
      ],
    };
    createNewChat(newChat).then((res: any) => {
      if (res.data) {
        onClickHandler(res.data);
      } else {
        alert('Error while creating chat!');
      }
    }).catch(() => {
      alert('Error while creating chat!');
    })
  }

  useEffect(() => {
    const userID: string | null = window.localStorage.getItem("userID");
    showAllChatsByUser(userID ?? "").then((res: any) => {
      if (res.data) {
        setChats(res.data);
      }
    }).catch(() => {
      setChats([]);
    })
  }, [openChatBox]);

  const backgroundStyle = {
    background: `radial-gradient(97.57% 210.75% at 0.9% 2.98%, rgba(216, 216, 216, 0) 0%, #D8D8D8 100%),
                radial-gradient(97.09% 224.61% at 1.38% 96.94%, rgba(216, 216, 216, 0) 0%, #D8D8D8 100%),
                linear-gradient(98.14deg, rgba(227, 227, 227, 0.15) 8.61%, rgba(211, 211, 211, 0) 101.27%),
                linear-gradient(285.83deg, rgba(240, 240, 240, 0.9) 33.73%, rgba(255, 255, 255, 0) 93.25%)`,
    /* Other styles like width, height, padding, etc., can be added here */
    width: '100%',
    height: '200px',
    /* Add any other necessary styles */
  };

  return (
    <div>
      <div className="position-relative px-3 py-3 rounded chat-container">
        <div className="row">
          <div className="col">
            <h3>Ask Ctrials.ai</h3>
            {
              chats.length > 0 && openChatBox ? (
                <p style={{ color: '#000', fontSize: '13px', marginTop: '-5px' }}>Chat History</p>
              ) : <></>
            }
          </div>
        </div>
        {openChatBox ? (
          <div>
            <div className="row mt-4 hidden-scrollbar chat-scroll">
              {chats.map((item: IndividualChat) => (
                <div
                  className="col-12 "
                  id="chatItem"
                  style={{ padding: '10px 15px', borderRadius: '7px', cursor: 'pointer' }}
                  onClick={() => onClickHandler(item)}
                >
                  <h6 style={{ color: '#171A1D' }}>{item.title}</h6>
                  <p style={{ color: "#B7BEC4", fontSize: '13px' }}>Last : {item.end}</p>
                </div>
              ))}
            </div>
            <div className="row d-flex justify-content-center align-items-center pt-4 ms-3">
              <div className="col-8">
                <button
                  className="btn font-weight-500 rounded d-flex gap-4 border-color pt-2 mt-3 background-color"
                  onClick={() => newChatHandler()}
                >
                  <span
                    className={"font-size-14 font-weight-400 line-height-19 d-flex gap-2"}
                  >
                    <span>
                      <Add />
                    </span>
                    <span className="mt-1 text-white">

                      New Chat
                    </span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        ) : (
          <ChatContainerScreen
            chat={individualChat}
            setCurrentQuestion={setCurrentQuestion}
            defaultClinicalTrialList={defaultClinicalTrialList}
            setOpenChatBox={(value: boolean) => setOpenChatBox(value)}
            setClinicalTrialResult={setClinicalTrialResult}
          />
        )}
        {/* <div
          style={{
            position: "absolute",
            right: "95px",
            top: "-100px",
            zIndex: "-1",
          }}
        >
          <Vector />
        </div>
        <div
          style={{
            position: "absolute",
            right: "110px",
            top: "400px",
            zIndex: "-1",
          }}
        >
          <Vector />
        </div> */}
      </div>
    </div>
  );
};

export default ChatType;
