import { Fragment, useEffect, useState } from "react";
import { getChatResponseFromAI } from "../../api-services/chat.api";
import { getGraphData } from "../../utils/graph";
import { extractCommonLocation } from "../../utils/utility";
import Loader from "../Loader";
import MapBoxMap from "../MapBoxMap";
import Blog from "./Blogs";
import Footer from "./Footer";
import Graph from "./Graph";
import { DEFAULT_DATA } from "./StaticFile";

interface IDashboardScreen {
  currentQuestion: any;
  generating: boolean;
  setIsGenerating: any;
}

interface IData {
  name: string;
  value: number;
}

const DashboardScreen: React.FC<IDashboardScreen> = ({
  generating,
  setIsGenerating,
  currentQuestion,
}) => {
  const [currentTrial, setCurrentTrial] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    setIsGenerating(true);
    if (currentQuestion === DEFAULT_DATA.defaultQuestion) {
      setCurrentTrial(DEFAULT_DATA.trails);
      setIsGenerating(false);
    } else {
      getChatResponseFromAI(currentQuestion)
        .then((res) => {
          if (res?.data?.trials) {
            setCurrentTrial(res.data.trials);
          } else {
            setCurrentTrial(DEFAULT_DATA.trails);
          }
          setIsGenerating(false);
        })
        .catch((err) => {
          setCurrentTrial(DEFAULT_DATA.trails);
          setIsGenerating(false);
        });
    }
  }, [currentQuestion]);

  return (
    <Fragment>
      <div className={"d-flex col-md-12 px-5"} >
        {generating ? <Loader /> : <></>}
        <div className="row" style={generating ? { filter: 'blur(8px)', pointerEvents: 'none' } : {}}>

          <div
            className="col-12"
            style={{
              borderStyle: "solid",
              borderWidth: "1px 0 0 0",
              borderColor: "rgba(87, 87, 87, 0.29)",
            }}
          ></div>
          <div
            className={"col-md-8"}
            style={{
              borderStyle: "solid",
              borderWidth: "0 1px 0 0",
              borderColor: "rgba(87, 87, 87, 0.29)",
            }}
          >
            <label
              className={"pt-3"}
              style={{
                fontSize: "24px",
                lineHeight: "52px",
                textAlign: "left",
                fontFamily: "Poppins-Semi-Bold",
                color: "rgba(0, 0, 0, 1)",
              }}
            >
              Showing Results
            </label>
            <p
              style={{
                fontSize: "16px",
                lineHeight: "24px",
                color: "rgba(97, 184, 117, 1)",
                fontFamily: "Poppins-Medium",
              }}
            >
              {currentQuestion}
            </p>
            {getGraphData(currentTrial ?? []).map((data: any, idx: number) => (
              <Graph title={data?.title} data={data?.data} key={idx} />
            ))}
            <label
              className={"pb-3"}
              style={{ fontWeight: "700", fontSize: "16px" }}
            >
              Location
            </label>
            <div
              style={{
                width: '100%',
                height: "300px",
                position: 'relative'
              }}
            >
              <MapBoxMap setLoading={setLoading} id="mapbox" locations={extractCommonLocation(DEFAULT_DATA.trails).states} />
            </div>
          </div>

          <div className={"col-md-4"}>
            <label
              className={"pt-4"}
              style={{
                fontSize: "24px",
                lineHeight: "52pxpx",
                textAlign: "left",
                fontFamily: 'Poppins-Semi-Bold',
                color: 'rgba(0, 0, 0, 1)',
                width: "100%",
                marginLeft: '8px'
              }}
            >
              Clinical Trials
            </label>
            <div className="hidden-scrollbar dashboard-blog-scroll">
              {currentTrial.length ? currentTrial.map((item, index) => {
                return <Blog data={item} />
              }) : <></>}
            </div>

          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default DashboardScreen;
