import React, { useState, useEffect } from "react";
import ClinicalTrial from "./ClinicalTrial";
import ClinicalTrialFilter from "./ClinicalTrialFilter";
import ChatType from "./ChatType";
import { getAllClinicalResults, getPatientByID } from "../../api-services/apis";
import { useParams } from "react-router-dom";
import { ReactComponent as LeftSideVector } from "../../assets/icon/clinicalTrial/LeftSideVector.svg";
import { DEFAULT_DATA } from "../../components/dashboard/StaticFile";

interface ICheckboxState {
  [key: string]: string[];
}

const defaultQuestion = "NSCLC patient with EGFR mutation progressed on osimertinib";

const ClinicalTrialScreen = () => {
  const [checkboxState, setCheckboxState] = useState<ICheckboxState>({});
  const [defaultClinicalTrialList, setDefaultClinicalTrialList] = useState([]);
  const [clinicalTrialResult, setClinicalTrialResult] = useState<any[]>([]);
  const { id } = useParams();
  const [currentQuestion, setCurrentQuestion] = useState<string>(defaultQuestion);
  useEffect(() => {
    // getAllClinicalResults()
    //   .then((res: any) => {
    //     if (res.data) {
    //       setDefaultClinicalTrialList(res.data);
    //       setClinicalTrialResult(res.data);
    //     } else {
    //       setClinicalTrialResult([]);
    //     }
    //   })
    //   .catch(() => {
    //   });
    setClinicalTrialResult(DEFAULT_DATA?.trails ?? []);
  }, []);

  useEffect(() => {
    if (id) {
      getPatientByID(id?.toString() ?? "")
        .then((res: any) => {
          if (res.data) {
            setCurrentQuestion("Patient " + res?.data?.name ?? 'Unknown');
            setClinicalTrialResult(res.data.trials);
          } else {
            setClinicalTrialResult([]);
          }
        })
        .catch((err: any) => {
          setClinicalTrialResult([]);
        });
    }
  }, [id]);
  return (
    <div>
      <div>
        <div className="row" style={{ position: "relative" }}>
          <div className="col-md-3">
            <ChatType
              currentQuestion={currentQuestion}
              setCurrentQuestion={setCurrentQuestion}
              setClinicalTrialResult={setClinicalTrialResult}
              defaultClinicalTrialList={defaultClinicalTrialList}
            />
          </div>
          <div className="col-md-3 mb-5">
            <ClinicalTrialFilter
              checkboxState={checkboxState}
              clinicalTrialResult={clinicalTrialResult}
              setCheckboxState={setCheckboxState}
            />
          </div>
          <div className="col-md-6 mb-5">
            <ClinicalTrial
              currentQuestion={currentQuestion}
              checkboxState={checkboxState}
              clinicalTrialResult={clinicalTrialResult}
            />
          </div>
          <div style={{ position: "absolute", left: "-47px", zIndex: '-1' }}>
            <LeftSideVector height={246} />
          </div>
          <div style={{ position: "absolute", left: "-47px", zIndex: '-1', bottom: '58px' }}>
            <LeftSideVector height={250} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClinicalTrialScreen;
