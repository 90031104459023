export function getGender(genderCode: string) {
  if (genderCode == "M") {
    return "Male";
  } else if (genderCode == "F") {
    return "Female";
  } else {
    return "Unknown";
  }
}

export function randomNameGenerator(num: number) {
  let res = "";
  for (let i = 0; i < num; i++) {
    const random = Math.floor(Math.random() * 27);
    res += String.fromCharCode(97 + random);
  }
  return res;
}

export function extractCommonLocation(trials: any[]) {
  const states: any[] = [];
  const country: any[] = [];
  const location: any[] = [];
  const countryStateMapper: any = {};
  for (let item of trials) {
    for (let loc of item?.locations ?? []) {
      if (countryStateMapper[loc?.country]) {
        if (countryStateMapper[loc?.country].includes(loc?.state ?? "")) {
          countryStateMapper[loc?.country].push(loc?.state ?? "");
        }
      } else {
        countryStateMapper[loc?.country] = [loc?.state ?? ""];
      }
      states.push(loc?.state ?? "");
      country.push(loc?.country ?? "");
      location.push(loc);
    }
  }
  const uniqueCountries = country.filter((value, index, self) => {
    return self.indexOf(value) === index;
  });
  const uniqueStates = states.filter((value, index, self) => {
    return self.indexOf(value) === index;
  });
  const uniqueLocation = location.filter((value, index, self) => {
    return self.indexOf(value) === index;
  });

  return {
    states: uniqueStates,
    countries: uniqueCountries,
    location: uniqueLocation,
    countryStateMapper,
  };
}
