import React, { Fragment, ReactElement, useState } from 'react';
import { useNavigate } from 'react-router';

import Header from './Header';
import AIChat from './AIChat';
import DashboardScreen from '../../components/dashboard/DashboardScreen';
import CreateAccountModal from '../auth/CreateAccountModal';
import LoginModal from '../auth/LoginModal';
import OTPModal from '../auth/OTPModal';
import AccountSetupModal from '../auth/AccountSetupModal';

const HomePage = (): ReactElement => {
  const navigate = useNavigate();

  const [authType, setAuthType] = useState<string>('');
  const [isSignUpModalOpened, setIsSignUpModalOpened] = useState<boolean>(false);
  const [isSignInModalOpened, setIsSignInModalOpened] = useState<boolean>(false);
  const [isOTPModalOpened, setIsOTPModalOpened] = useState<boolean>(false);
  const [isAccountSetupModalOpened, setIsAccountSetupModalOpened] = useState<boolean>(false);
  const [modalMode, setModalMode] = useState<string>("");
  const [email, setEmail] = useState<string>('');
  const [currentQuestion, setCurrentQuestion] = useState<string>("NSCLC patient with EGFR mutation progressed on osimertinib");
  const [isGenerating, setIsGenerating] = useState(false);

  return (
    <Fragment>
      <Header
        onClickLogin={() => setIsSignUpModalOpened(true)}
      />
      <hr className="mt-0" />
      <AIChat isGenerating={isGenerating} setCurrentQuestion={setCurrentQuestion} setIsSignUpModalOpened={setIsSignUpModalOpened} />
      <DashboardScreen generating={isGenerating} currentQuestion={currentQuestion} setIsGenerating={setIsGenerating} />

      {
        isSignUpModalOpened &&
        <CreateAccountModal
          emailId={email}
          onClickLogin={() => {
            setIsSignInModalOpened(true);
            setIsSignUpModalOpened(false);
          }}
          onSubmit={(type: string, value: string) => {
            setAuthType(type);
            setIsSignUpModalOpened(false);
            setIsOTPModalOpened(true);
            setEmail(value);
            setModalMode("SignUp");
          }}
          onClose={() => {
            setIsSignUpModalOpened(false);
            setModalMode("");
            setIsOTPModalOpened(false);
          }}
        />
      }

      {
        isSignInModalOpened &&
        <LoginModal
          emailId={email}
          onClickSignUp={() => {
            setIsSignUpModalOpened(true);
            setIsSignInModalOpened(false);
          }}
          onSubmit={(type: string, value: string) => {
            setAuthType(type);
            setIsSignInModalOpened(false);
            setIsOTPModalOpened(true);
            setModalMode("SignIn");
            setEmail(value);
          }}
          onClose={() => {
            setIsSignInModalOpened(false);
            setModalMode("");
            setIsOTPModalOpened(false);
          }}
        />
      }

      {
        isOTPModalOpened &&
        <OTPModal
          authType={authType}
          emailId={email}
          onSubmit={() => {
            console.log({ modalMode })
            if (modalMode == 'SignIn') {
              sessionStorage.setItem('user-token', '1');
              navigate('/clinical-trials')
            } else {
              setIsAccountSetupModalOpened(true)
            }
          }
          }
          onClickBack={(type: string, email: string) => {
            if (type === 'SignUp') {
              setIsSignUpModalOpened(true);
            } else {
              setIsSignInModalOpened(true);
            }
            setEmail(email);
          }}
        />
      }

      {
        isAccountSetupModalOpened &&
        <AccountSetupModal
          emailId={email}
          onSubmit={() => {
            setIsAccountSetupModalOpened(false);
            sessionStorage.setItem('user-token', '1');
            navigate('/clinical-trials');
          }}
        />
      }
    </Fragment>
  );
};

export default HomePage;
