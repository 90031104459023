import { Fragment, useEffect, useState } from 'react';
import { ReactComponent as Expand } from '../../assets/icon/clinicalTrial/expandIcon.svg';
import { ReactComponent as ExpandClose } from '../../assets/icon/clinicalTrial/expandMore.svg';
import { CLINICAL_FILTER_DETAILS } from "./StaticData";
import { extractCommonLocation } from '../../utils/utility';
import resetIcon from '../../assets/image/resetIcon.png';
interface IExpand {
  name: string;
  title: string;
}

interface ICheckboxState {
  [key: string]: string[];
}

interface MyClinicalTrialFilter {
  checkboxState: ICheckboxState;
  setCheckboxState: any;
  clinicalTrialResult: any
}

const ClinicalTrialFilter: React.FC<MyClinicalTrialFilter> = ({ checkboxState, setCheckboxState, clinicalTrialResult }) => {
  const [expandedSections, setExpandedSections] = useState<any>({
    interventions: true
  });
  const expand: IExpand[] = [
    { name: 'interventions', title: 'Interventions' },
    { name: 'recruitingStatus', title: 'Recruiting status' },
    { name: 'phase', title: 'Phase' },
    { name: 'location', title: 'Location' }
  ];
  const [countriesAndState, setCountriesAndState] = useState<any>({});
  const [country, setCountry] = useState<string>('');
  const [states, setStates] = useState<any[]>([]);
  const [iconStates, setIconStates] = useState<{ [key: string]: boolean }>({});

  const toggleExpand = (section: any) => {
    setExpandedSections({
      [section]: !expandedSections[section],
    });

    setIconStates({
      [section]: !iconStates[section],
    });
  };

  const handleCheckboxChange = (section: string, value: string) => {
    const currentState = checkboxState[section] || [];

    const isChecked = currentState.includes(value);
    let updatedCheckboxes;

    if (isChecked) {
      updatedCheckboxes = currentState.filter((checkbox) => checkbox !== value);
    } else {
      updatedCheckboxes = [...currentState, value];
    }

    setCheckboxState({
      ...checkboxState,
      [section]: updatedCheckboxes,
    });
  };

  useEffect(() => {
    if (country && country !== 'All') {
      // @ts-ignore
      setStates([...new Set([...countriesAndState[country]])]);
    } else {
      setStates([]);
    }
  }, [country]);

  useEffect(() => {
    const { countryStateMapper } = extractCommonLocation(clinicalTrialResult ?? []);

    setCountriesAndState(countryStateMapper);
  }, [clinicalTrialResult])



  const handleSingleCheckboxChange = (section: string, value: string) => {
    setCheckboxState({
      ...checkboxState,
      [section]: [value], // Update the state with the selected single value
    });
  };

  const handleInputChange = (section: string, value: string) => {
    setCheckboxState({
      ...checkboxState,
      [section]: [value], // Update the state with the entered value
    });
  };

  const renderCheckboxes = (section: any) => {
    if (section === 'age' || section === 'condition' || section === 'metastaticSite' || section === 'Other') {
      const placeholderText =
        section === 'age'
          ? 'Age'
          : section === 'condition'
            ? 'Condition'
            : section === 'metastaticSite'
              ? 'Metastatic Site'
              : 'Other';
      return (
        expandedSections[section] && (
          <div className='custom-checkbox-filter mt-1 cursor-pointer'>
            {section === 'age' ? ( // Check if the section is 'age'
              <input
                type="number" // Set input type to 'number'
                className="form-control mt-2"
                placeholder={`Enter ${placeholderText}`}
                value={checkboxState[section]?.[0] || ''}
                onChange={(e) => handleInputChange(section, e.target.value)}
                min={1} // Set minimum value
                max={130}
              />
            ) : (
              <input
                type="text"
                className="form-control mt-2"
                placeholder={`Enter ${placeholderText}`}
                value={checkboxState[section]?.[0] || ''}
                onChange={(e) => handleInputChange(section, e.target.value)}
              />
            )}
          </div>
        )
      );
    } else if (section === 'location') {
      return (
        expandedSections[section] && (
          <>
            <div className='custom-checkbox-filter mt-1 cursor-pointer'>
              <label htmlFor="countryDropdown" className='font-size-14 mt-1' style={{ fontFamily: 'Poppins-Regular' }}>Country:</label>
              <select
                id="countryDropdown"
                className="form-select mt-2"
                // @ts-ignore
                value={checkboxState?.[section]?.['country'] ?? 'All'}
                onChange={(e) => {
                  setCountry(e.target.value);
                  setCheckboxState({
                    ...checkboxState,
                    [section]: {
                      ...checkboxState[section],
                      country: e.target.value
                    },
                  })
                }}
              >
                <option value="All">-Select-</option>
                {/* Generate options for countries */}
                {Object.keys(countriesAndState)?.map((countryOption, index) => (
                  <option key={index} value={countryOption}>
                    {countryOption}
                  </option>
                ))}
              </select>
            </div >
            <div className='custom-checkbox-filter mt-1 cursor-pointer'>
              <label htmlFor="stateDropdown" className='font-size-14 mt-1' style={{ fontFamily: 'Poppins-Regular' }}>City:</label>
              <select
                id="stateDropdown"
                className="form-select mt-2"
                // @ts-ignore
                value={checkboxState?.[section]?.['state'] ?? 'All'}
                onChange={(e) => {
                  setCheckboxState({
                    ...checkboxState,
                    [section]: {
                      ...checkboxState[section],
                      state: e.target.value
                    },
                  })
                }}
              >
                <option value="All">-Select-</option>
                {/* Generate options for states */}
                {states?.map((stateOption, index) => (
                  <option key={index} value={stateOption}>
                    {stateOption}
                  </option>
                ))}
              </select>
            </div>
          </>
        )
      );
    }
    else if (
      section === 'location' ||
      section === 'gender' ||
      section === 'ecog' ||
      section === 'mainTumorType' ||
      section === 'tumorSubtype' ||
      section === 'stage' ||
      section === 'metastasis' ||
      section === 'PDL1' ||
      section === 'ER' ||
      section === 'PR' ||
      section === 'HER2'
    ) {
      return (
        expandedSections[section] && (
          <>
            {CLINICAL_FILTER_DETAILS[section]?.map((option, index) => (
              <div className='custom-checkbox-filter d-flex gap-2 mt-1' key={index}>
                <input
                  type="checkbox"
                  id={`${section}-${index}`} // Set unique IDs for each checkbox
                  className="form-check-input mt-2"
                  checked={checkboxState[section]?.includes(option.value)}
                  onChange={() => handleSingleCheckboxChange(section, option.value)}
                />
                {/* Associate label with checkbox using htmlFor attribute */}
                <label htmlFor={`${section}-${index}`} className='mt-2 font-size-14' style={{ fontFamily: 'Poppins-Regular', cursor: 'pointer' }}>
                  {option.label}
                </label>
              </div>
            ))}
          </>
        )
      );
    }

    return (
      expandedSections[section] && (
        <>
          {CLINICAL_FILTER_DETAILS[section]?.map((option, index) => (
            <div className='custom-checkbox-filter d-flex gap-2 mt-1' key={index}>
              <input
                type="checkbox"
                id={`${section}-${index}`} // Set unique IDs for each checkbox
                className="form-check-input mt-2 cursor-pointer"
                checked={checkboxState[section]?.includes(option.value)}
                onChange={() => handleCheckboxChange(section, option.value)}
              />
              {/* Associate label with checkbox using htmlFor attribute */}
              <label htmlFor={`${section}-${index}`} className='mt-2 font-size-14' style={{ fontFamily: 'Poppins-Regular', cursor: "pointer" }}>
                {option.label}
              </label>
            </div>
          ))}
        </>
      )
    );
  };

  const headerSections = (name: string, label: string) => {
    return (
      <Fragment>
        <hr />
        <span className='font-weight-700'>{label}</span>
      </Fragment>
    );
  };

  return (
    <div>
      <div className='border-color mt-4 p-2 rounded-2'>
        <div className=''>
          <div className='box' style={{ padding: '20px' }}>
            <div style={{ display: 'flex', justifyContent: "space-between", alignItems: "center", marginBottom: "1rem" }}>
              <span className='font-size-16' style={{ fontFamily: 'Poppins-Medium' }}>Filters</span>
              <img onClick={() => { setCheckboxState({}) }} src={resetIcon} alt="reset" style={{ width: "20px", height: '20px', cursor: 'pointer', filter: "opacity(40%)" }} />
            </div>
            {expand.map((child: IExpand) => (
              <div key={child.name} >
                <div style={{ cursor: "pointer", display: 'flex', width: "100%", justifyContent: 'space-between' }} className=' gap-1 mt-2' onKeyUp={() => toggleExpand(child.name)} onClick={() => toggleExpand(child.name)}>
                  <span className='font-size-14 mt-1' style={{ fontFamily: 'Poppins-Regular' }}>{child.title}</span>
                  <div>
                    {iconStates[child.name] ? <ExpandClose /> : <Expand />}
                  </div>
                </div>
                {renderCheckboxes(child.name)}
                {(child.name === 'location') ||
                  (child.name === 'comorbidities' && headerSections('comorbidities', 'Diagnosis')) ||
                  (child.name === 'diagnosisOfInfection' && headerSections('diagnosisOfInfection', 'Genomic Markers')) ||
                  (child.name === 'otherGenomicMarkers' && headerSections('otherGenomicMarkers', 'IHC markers')) ||
                  (child.name === 'Other' && headerSections('Other', 'Treatment status'))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClinicalTrialFilter;
