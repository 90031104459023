import React, { useState } from "react";
import { ReactComponent as Spark } from "../../assets/icon/landing_dashboard/sparkling-fill.svg";
import { ReactComponent as SparkBlue } from "../../assets/icon/landing_dashboard/sparkling-fill-blue.svg";
import { ReactComponent as Vector } from "../../assets/icon/clinicalTrial/Vector.svg";

const chatData: string[] = [
  "NSCLC patient with EGFR mutation progressed on osimertinib",
  "Find a trial for NSCLC with EGFR exon 19 deletion",
  "Lung Cancer with EGFR mutation treated with systemic chemotherapy",
  "Find a Trial Endometrial cancer with brain metastasis",
];

interface IAIChat {
  isGenerating: boolean;
  setIsSignUpModalOpened: any;
  setCurrentQuestion: any;
}

const AIChat: React.FC<IAIChat> = ({
  isGenerating,
  setCurrentQuestion,
  setIsSignUpModalOpened,
}) => {
  const [indexedIcon, setIndexedIcon] = useState(0);
  return (
    <div className={"d-flex flex-column justify-content-center py-2"}>
      <div>
        <div className={"d-flex justify-content-center"}>
          <label
            className={"font-sez-16 text-secondary"}
            style={{
              fontFamily: "Poppins-Medium",
            }}
          >
            Looking for the right trials for your patient?
          </label>
          <br />
        </div>
        <div className={"d-flex justify-content-center"}>
          <label
            className={"text-center text-black"}
            style={{
              fontSize: "44px",
              fontFamily: "Poppins-Regular",
              lineHeight: "44px",
            }}
          >
            Find most relevant{" "}
            <span
              className={"fw-bolder Poppins-Bold"}
              style={{
                background:
                  "linear-gradient(97.79deg, #61B875 10.02%, #4DC4D2 92.86%)",
                WebkitBackgroundClip: "text", // For Safari compatibility
                color: "transparent",
                fontFamily: "Poppins-Bold",
              }}
            >
              Clinical trials
            </span>
            <br />
            here
          </label>
        </div>
      </div>
      <div className={"py-5 mx-5"}>
        <div
          className={"d-flex justify-content-center flex-grow-1 my-2"}
          style={{ position: "relative" }}
        >
          <div className={"mx-5 landing-chatBox mt-2"}>
            <div>
              <label
                className={
                  "d-flex justify-content-center py-3 chat-ai-label mt-2"
                }
              >
                <SparkBlue />
                <span
                  className="text-secondary font-size-16"
                  style={{ fontFamily: "Poppins-Medium", marginLeft: '10px', marginBottom: "1rem" }}
                >
                  Try our AI to search the right clinical trial for a patient
                </span>
              </label>
            </div>
            <div className={"d-flex flex-column px-5"}>
              <div className={"d-flex flex-wrap justify-content-between px-4"}>
                {chatData.map((item, index) => {
                  return (
                    <div
                      className={
                        "d-flex justify-content-center my-2 px-3"
                      }
                      key={index}
                      style={index === indexedIcon ? {
                        borderRadius: '12px',
                        backgroundColor: " #FFFFFF",
                        border: "4px solid rgba(234, 235, 241, 1)",
                      } : {
                        borderRadius: '12px',
                        backgroundColor: " #FFFFFF",
                        border: "2px solid rgba(234, 235, 241, 1)",
                      }}
                      onClick={() => {
                        setCurrentQuestion(chatData[index]);
                        setIndexedIcon(index);
                      }}
                    >
                      <label
                        className={
                          "text-center py-2 Poppins-Regular text-black font-size-14"
                        }
                        style={{ marginRight: '10px' }}
                      >
                        {item}
                        {index === indexedIcon ? (
                          <SparkBlue
                            style={{ marginLeft: "1rem" }}
                            id={isGenerating ? "starTrek" : ""}
                          />
                        ) : (
                          <></>
                        )}
                      </label>
                    </div>
                  );
                })}
              </div>
              <div
                className={"d-flex my-5"}
                style={{ position: "relative", width:'60%', margin:'auto' }}
              >
                <input
                  onClick={() => {
                    setIsSignUpModalOpened(true);
                  }}
                  onInput={() => {
                    setIsSignUpModalOpened(true);
                  }}
                  placeholder={"Enter your query here"}
                  className={"w-100 py-3 ps-5"}
                  style={{
                    outline: "none",
                    borderWidth: "1.4px",
                    borderColor: "#61B875",
                    borderRadius:'15px'
                  }}
                />
                <button
                  className={"px-5 py-3 serchbar-button"}
                  style={{
                    position: "absolute",
                    right: "0px",
                    height: "100%",
                  }}
                  onClick={() => {
                    setIsSignUpModalOpened(true);
                  }}
                >
                  <Spark className={"me-2"} style={{ top: "50" }} />
                  <span className="text-white">Search</span>
                </button>
              </div>
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              right: "-22px",
              top: "-100px",
              zIndex: "-1",
            }}
          >
            <Vector />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AIChat;
