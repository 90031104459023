export const CLINICAL_FILTER_DETAILS: {
  [key: string]: { label: string; value: string }[];
} = {
  interventions: [
    { label: "Biological", value: "BIOLOGICAL" },
    { label: "Combination Product", value: "COMBINATION_PRODUCT" },
    { label: "Dietary Supplement", value: "DIETARY_SUPPLEMENT" },
    { label: "Drug", value: "DRUG" },
    { label: "Genetic", value: "GENETIC" },
    { label: "Radiation", value: "RADIATION" },
    { label: "Other", value: "OTHER" },
  ],
  recruitingStatus: [
    { label: "Not yet recruiting", value: "NOT_YET_RECRUITING" },
    { label: "Recruiting", value: "RECRUITING" },
    { label: "Active, not recruiting", value: "ACTIVE_NOT_RECRUITING" },
    { label: "Completed", value: "COMPLETED" },
    { label: "Terminated", value: "TERMINATED" },
    { label: "Enrolling by invitation", value: "ENROLLING_BY_INVITATION" },
    { label: "Suspended", value: "SUSPENDED" },
    { label: "Withdrawn", value: "WITHDRAWN" },
    { label: "Unknown", value: "UNKNOWN" },
  ],
  phase: [
    { label: "Early Phase 1", value: "EARLY_PHASE1" },
    { label: "Phase 1", value: "PHASE1" },
    { label: "Phase 2", value: "PHASE2" },
    { label: "Phase 3", value: "PHASE3" },
    { label: "Phase 4", value: "PHASE4" },
  ],
  location: [
    { label: "Country", value: "Country" },
    { label: "State", value: "State" },
    { label: "City", value: "City" },
    { label: "Zipcode", value: "Zipcode" },
  ],
  gender: [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    { label: "Unknown", value: "Unknown" },
  ],
  ecog: [
    { label: "0", value: "0" },
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
  ],
  comorbidities: [
    { label: "Diabetes", value: "Diabetes" },
    { label: "Hypertension", value: "Hypertension" },
    { label: "Hypothyroidism", value: "Hypothyroidism" },
    { label: "Cardiovascular diseases", value: "Cardiovascular_diseases" },
    { label: "Other", value: "Other" },
  ],
  mainTumorType: [
    { label: "Lung cancer", value: "Lung_cancer" },
    { label: "Head and Neck cancer", value: "Head_and_Neck_cancer" },
    { label: "other", value: "other" },
  ],
  tumorSubtype: [
    { label: "Small cell lung cancer", value: "Small_cell_lung_cancer" },
    {
      label: "Non small cell lung cancer",
      value: "Non_small_cell_lung_cancer",
    },
    { label: "other", value: "other" },
  ],
  stage: [
    { label: "Stage I", value: "Stage_I" },
    { label: "Stage II", value: "Stage_II" },
    { label: "Stage III", value: "Stage_III" },
    { label: "Stage IV", value: "Stage_IV" },
    { label: "Unknown", value: "Unknown" },
  ],
  metastasis: [
    { label: "Localized", value: "Localized" },
    { label: "Advanced", value: "Advanced" },
    { label: "Metastatic", value: "Metastatic" },
    { label: "Unknown", value: "Unknown" },
  ],
  diagnosisOfInfection: [
    { label: "HPV", value: "HPV" },
    { label: "HBV", value: "HBV" },
    { label: "HIV", value: "HIV" },
    { label: "Other", value: "Other" },
    { label: "Unknown", value: "Unknown" },
  ],
  geneticMutation: [
    { label: "TP53", value: "TP53" },
    { label: "BRAF", value: "BRAF" },
    { label: "EGFR", value: "EGFR" },
    { label: "PIK3CA", value: "PIK3CA" },
    { label: "BRCA2", value: "BRCA2" },
    { label: "Unknown", value: "Unknown" },
  ],
  otherGenomicMarkers: [
    { label: "TMB", value: "TMB" },
    { label: "Microsatellite stability", value: "microsatelliteStability" },
    { label: "Mismatch repair", value: "mismatchRepair" },
    {
      label: "Homologous recombination repair",
      value: "homologousRecombinationRepair",
    },
    { label: "Unknown", value: "Unknown" },
  ],
  PDL1: [
    { label: "Positive", value: "Positive" },
    { label: "Negative", value: "Negative" },
    { label: "Unknown", value: "Unknown" },
  ],
  ER: [
    { label: "Positive", value: "Positive" },
    { label: "Negative", value: "Negative" },
    { label: "Unknown", value: "Unknown" },
  ],
  PR: [
    { label: "Positive", value: "Positive" },
    { label: "Negative", value: "Negative" },
    { label: "Unknown", value: "Unknown" },
  ],
  HER2: [
    { label: "Positive", value: "Positive" },
    { label: "Negative", value: "Negative" },
    { label: "Unknown", value: "Unknown" },
  ],
  // treated: [
  //   { label: 'Treated for localised disease', value: 'Treated_for_localised_disease' },
  //   { label: 'Treated for metastatic disease', value: 'Treated_for_metastatic_disease' },
  //   { label: 'Relapsed/Refractory/Resistant/Progressed on the standard treatment', value: 'Relapsed/Refractory/Resistant/Progressed_on_the_standard_treatment' },
  //   { label: 'Localized treatment given', value: 'Localized_treatment_given' },
  //   { label: 'Systemic treatment given', value: 'Systemic_treatment_given' },
  //   { label: 'Immunotherapy given', value: 'Immunotherapy_given' },
  // ]
};