import axios from "./axios";

export function getAllPatients() {
  return axios.get("/patients");
}

export function getPatientByID(id: string) {
  return axios.get(`/patients/${id}`);
}

export function addNewPatient(patient: any) {
  return axios.post("/patients", patient);
}

export function updatePatient(id: string, patient: any) {
  return axios.put(`/patients/${id}`, patient);
}

export function login(email: string) {
  return axios.get(`/users?email=${email}`);
}

export const register = (email: string) => {
  return axios.post("/users", {
    email,
  });
};

export function updateUser(
  id: string,
  first: string,
  last: string,
  email: string,
  contact: string
) {
  return axios.put(`/users/${id}`, {
    name: {
      first,
      last,
    },
    email,
    contact,
    medicalRegistrationNumber: "",
  });
}

export function getAllClinicalResults() {
  return axios.get(`/clinicalTrialResult`);
}

// Chats
interface IndividualMessage {
  type: string;
  message: string;
  timestamp: number;
}

interface IndividualChat {
  id?: string;
  userID: string;
  title: string;
  end: string;
  history: IndividualMessage[];
}
export function updateChatByID(id: string, chat: IndividualChat) {
  return axios.put(`/chats/${id}`, chat);
}

export function createNewChat(chat: IndividualChat) {
  return axios.post(`/chats`, chat);
}

export function showAllChatsByUser(userID: string) {
  return axios.get(`/chats?userID=${userID}`);
}

export function getDefaultQuestion() {
  return axios.get(`/defaultQuestion`);
}
