//import React Libraries
import React, { FC, Fragment, useEffect, useState } from "react";
import { ReactComponent as Star } from "../../../assets/icon/pataint/star.svg";
import { ReactComponent as Arrow } from "../../../assets/icon/pataint/arrowback.svg";
import { ReactComponent as ArrowRight } from "../../../assets/icon/pataint/arrowRight.svg";
import Logo from "../../../assets/icon/header/Nologoimage.png";
import { updateChatByID } from "../../../api-services/apis";
import { getChatResponseFromAI } from "../../../api-services/chat.api";
import Loader from "../../../components/Loader";
import { ReactComponent as ChartBot } from "../../../assets/icon/clinicalTrial/ChartBot.svg";

//interface

interface IndividualChat {
  id?: string;
  userID: string;
  title: string;
  end: string;
  history: IndividualMessage[];
}

interface IndividualMessage {
  type: string;
  message: string;
  timestamp: number;
}

interface IChatType {
  setCurrentQuestion: any;
  chat?: IndividualChat;
  defaultClinicalTrialList: any;
  setOpenChatBox: (value: boolean) => void;
  setClinicalTrialResult: (value: boolean) => void;
}

const ChatContainerScreen: FC<IChatType> = ({
  chat,
  setOpenChatBox,
  defaultClinicalTrialList,
  setCurrentQuestion,
  setClinicalTrialResult,
}) => {
  //Intilize state
  const [messageList, setMessageList] = useState<IndividualMessage[]>();
  const [message, setMessage] = useState("");
  const [chatTitle, setChatTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const handleInputChange = (e: any) => {
    setMessage(e.target.value);
  };

  useEffect(() => {
    if (chat?.history) {
      setMessageList(chat?.history ?? []);
    }
    setChatTitle(chat?.title ?? '');
  }, []);





  const handleSendMessage = (event: any) => {
    event.preventDefault();
    setIsLoading(true);
    if (message.trim() !== "") {
      let title: string = '';
      if (!chatTitle || chatTitle === 'New Chat') {
        title = message;
        setChatTitle(message);
      }
      const newMessage: IndividualMessage = {
        type: "HUMAN",
        message: message,
        timestamp: Date.now(),
      };
      // const messages: IndividualMessage[] = (chat?.history ?? [])
      const allMessages = [...messageList ?? []]
      setMessageList([...allMessages, newMessage]);
      getChatResponseFromAI(message).then((res: any) => {
        if (res.data.bot_response) {
          const botResponse = {
            type: 'BOT',
            message: res.data.bot_response,
            timestamp: Date.now()
          }
          setMessageList([...allMessages, newMessage, botResponse]);
          updateDBWithMessage([...allMessages, newMessage, botResponse], title);
          if (res.data?.trials && res.data?.trials?.length) {
            setCurrentQuestion(message);
            setClinicalTrialResult(res.data.trials)
          }
        } else {
          updateDBWithMessage([...allMessages, newMessage], title)
        }
        setIsLoading(false);
      }).catch((err: any) => {
        setIsLoading(false);
        updateDBWithMessage([...allMessages, newMessage], title)
      })
      setMessage("");
    }
  };


  const updateDBWithMessage = (allHistory: IndividualMessage[], title: string) => {
    const chatObject: IndividualChat = {
      id: chat?.id ?? '',
      userID: chat?.userID ?? '',
      title: title || chatTitle,
      end: new Date().toDateString(),
      history: allHistory ?? [],
    };
    updateChatByID(chat?.id ?? "", chatObject)
      .then((res: any) => {
        if (!res.data) {
          alert("Error while saving!");
        }
      })
      .catch(() => {
        alert("Error while saving!");
      });
  };
  return (
    <div style={{ position: "relative" }}>
      {isLoading && <Loader />}
      <div className="row">
        <div className="col-12">
          <div className="d-flex rounded border border-gray px-2 py-2">
            <span onClick={() => setOpenChatBox(true)} style={{ cursor: 'pointer' }}>
              <Arrow title={"Back to Chat history"} />
            </span>

            <span className="font-size-16 font-weight-500 ps-2">
              {(chat?.title ? chat?.title : chatTitle).length > 30 ? (chat?.title ? chat?.title : chatTitle).trim().slice(0, 30) + '...' : (chat?.title ? chat?.title : chatTitle)}
            </span>
          </div>
        </div>
      </div>
      <div className="mt-4 chat-scroll">
        {chat ? (
          <Fragment>
            <div className="row">
              {messageList ? (
                messageList.map((item: IndividualMessage, index) => {
                  return (
                    <div key={item.timestamp}>
                      {item.type == "BOT" ? (
                        <div className="row pe-1">
                          <div className="col-10 p-0 ps-4">
                            <div style={{ padding: "10px", overflow: 'auto' }} className="ps-4 bg-light rounded p-2 mb-2">
                              <p className="m-0">{item.message}</p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="row justify-content-end p-0">
                          {/* Right Message */}
                          <div className="col-8 text-end p-0 pe-4">
                            <div className="rounded p-2 mb-2 background-rgba217">
                              <p className="m-0">{item.message}</p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })
              ) : (
                <></>
              )}
            </div>
            {messageList?.length === 1 && (
              <div className="row">
                <ChartBot />
              </div>
            )}
          </Fragment>
        ) : (
          <div className="logo d-flex flex-column justify-content-center px-4 py-4 align-items-center margin-top-90px">
            <img src={Logo} alt="Logo" height={50} width={50} />
            <p className="font-weight-700 font-size-14">No ongoing chats</p>
            <p className="font-weight-500 font-size-14">
              Explore Clinical Trials Together
            </p>
          </div>
        )}
      </div>
      {/* Footer with Input Box */}
      <form className="row mt-3" onSubmit={handleSendMessage}>
        <div className={"d-flex flex-column flex-grow-1 position-relative"} style={{ height: '45px' }}>
          <textarea
            placeholder={"Type here"}
            style={{ wordWrap: 'normal', wordBreak: "break-all", overflow: "scroll", flex: 1, height: '100%', paddingRight: '53px' }}
            className={
              "outline-none border-width-1px border-color w-100 py-2 rounded-4 ps-2 hidden-scrollbar"
            }
            rows={1}
            value={message}
            disabled={isLoading}
            onKeyUp={(e) => {
              e.preventDefault();
              if (e.key === 'Enter') {
                handleSendMessage(e);
              }
            }}
            onChange={handleInputChange}
          />
          <button
            style={{ backgroundColor: "#4dc4d2", height: '100%' }}
            className={"py-2 px-3 chat-button background-color"}
            type={"submit"}
          >
            {isLoading ? <Star id="starTrek" /> : <ArrowRight />}
          </button>
        </div>
      </form>
    </div>
  );
};
export default ChatContainerScreen;
