interface IObject {
  title: string;
  data: IData[];
}
interface IData {
  name: string;
  value: number;
}

export const getGraphData = (clinicalTrails: any[], filter: any = {}) => {
  const recruitingStatusData: any = {};
  const interventionStatusData: any = {};
  const phasesStatusData: any = {};
  for (const item of clinicalTrails) {
    const recruitingStatus = item.recruitingStatus?.toString()?.trim() ?? "";
    if (recruitingStatusData?.[recruitingStatus]) {
      recruitingStatusData[recruitingStatus].value =
        recruitingStatusData[recruitingStatus].value + 1;
    } else {
      recruitingStatusData[recruitingStatus] = {
        name: recruitingStatus,
        value: 1,
      };
    }

    // For Interventions
    const interventions = item?.interventions ?? [];

    for (const inter of interventions) {
      const interData = inter.trim();
      if (interventionStatusData?.[interData]) {
        interventionStatusData[interData].value =
          interventionStatusData[interData].value + 1;
      } else {
        interventionStatusData[interData] = {
          name: interData,
          value: 1,
        };
      }
    }
    // For phases
    const phases = item?.phase ?? [];

    for (const singlePhase of phases) {
      const singlePhaseData = singlePhase.trim();
      if (phasesStatusData?.[singlePhaseData]) {
        phasesStatusData[singlePhaseData].value =
          phasesStatusData[singlePhaseData].value + 1;
      } else {
        phasesStatusData[singlePhaseData] = {
          name: singlePhaseData,
          value: 1,
        };
      }
    }
  }

  let interventionsArray = [...Object.values(interventionStatusData)];
  let recruitingStatusArray = [...Object.values(recruitingStatusData)];
  let phasesStatusDataArray = [...Object.values(phasesStatusData)];
  if (filter && Object.keys(filter).length > 0) {
    if (filter.interventions && Object.keys(filter.interventions).length > 0) {
      interventionsArray = interventionsArray.filter((item: any) => {
        return filter.interventions
          .join("")
          .toLowerCase()
          .includes(item.name.toLowerCase());
      });
    }
    if (
      filter.recruitingStatus &&
      Object.keys(filter.recruitingStatus).length > 0
    ) {
      recruitingStatusArray = recruitingStatusArray.filter((item: any) => {
        return filter.recruitingStatus
          .join("")
          .toLowerCase()
          .includes(item.name.toLowerCase());
      });
    }
    if (filter.phase && Object.keys(filter.phase).length > 0) {
      phasesStatusDataArray = phasesStatusDataArray.filter((item: any) => {
        return filter.phase
          .join("")
          .toLowerCase()
          .includes(item.name.toLowerCase());
      });
    }
  }
  return [
    {
      title: "Interventions",
      data: interventionsArray,
    },
    {
      title: "Recruiting Status",
      data: recruitingStatusArray,
    },
    {
      title: "Phase",
      data: phasesStatusDataArray,
    },
  ];
};
export const getSuggestedGraphData = (clinicalTrails: any[]) => {
  let onGoingTrial = 0;
  let completedTrial = 0;
  for (const item of clinicalTrails) {
    const recruitingStatus = item.recruitingStatus?.toString()?.trim() ?? "";
    if (recruitingStatus.toLowerCase() === "recruiting") {
      onGoingTrial++;
    } else if (recruitingStatus.toLowerCase() === "completed") {
      completedTrial++;
    }
  }
  return [
    {
      name: "Ongoing trials",
      value: onGoingTrial,
    },
    {
      name: "Completed trials",
      value: completedTrial,
    },
  ];
};
