import React, { ReactElement, useState } from 'react';
import Modal from 'react-bootstrap/Modal';

import Back from '../../assets/icon/pataint/arrowback.svg';
import Google from '../../assets/icon/google-logo.svg';

import { register } from '../../api-services/apis';

interface IProps {
  emailId: string,
  onClickLogin: () => void,
  onSubmit: (type: string, value: string,) => void,
  onClose: () => void,
}

const CreateAccountModal = (props: IProps): ReactElement => {
  const { emailId, onClickLogin, onSubmit, onClose } = props;

  const [email, setEmail] = useState<string>(emailId);
  const [isValid, setIsValid] = useState<boolean>(true);

  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  const submitHandler = (): void => {
    const checkValid = emailRegex.test(email);

    if (!checkValid) {
      setIsValid(false);
      return;
    } else {
      register(email)
        .then((res: any) => {
          if (res.data.id) {
            localStorage.setItem('userID', res.data.id);
            onSubmit('SignUp', email);
          } else {
            alert('Error while creating account!');
          }
        })
        .catch(() => {
          alert('Error while creating account!');
        });
    }
  };

  return (
    <Modal size="lg" show={true}>
      <div className="background-left-bottom"/>
      <div className="background-right-top"/>
      <Modal.Header>
        <div className="cursor-pointer" onClick={() => onClose()}>
          <img src={Back} alt="back"/>
          <span className="modal-back">Back</span>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-12 text-center">
            <p className="modal-title">Create Account</p>
            <p className="modal-sub-title">Let’s get started</p>

            <input
              type="text"
              placeholder="Enter your email address"
              className="mt-3 p-2 border-0 border-bottom no-outline w-100"
              onChange={(e) => {
                setEmail(e.target.value);
                setIsValid(true);
              }}
              value={email}
            />
            {
              !isValid
                ? <span className="text-danger">Please enter valid email</span>
                : null
            }
            <button
              className="button btn-bg mt-4 border-0 fw-bold rounded d-block w-100"
              onClick={() => submitHandler()}
            >
              Continue with email
            </button>
            <button
              className="button mt-4 fw-bold rounded bg-white d-block w-100"
            >
              <img src={Google} alt="google" className="pe-2"/> Continue with google
            </button>
            <p className="modal-text mt-3">
              Already have an account?{' '}
              <span
                data-bs-toggle="modal"
                data-bs-target="#login-modal"
                className="cursor-pointer text-primary border-bottom"
                onClick={() => onClickLogin()}
              >
                Log in
              </span>
            </p>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CreateAccountModal;
