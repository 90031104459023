import React, { FC } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";

interface IProps {
  children: any;
}

const Layout: FC<IProps> = ({ children }) => {
  return (
    <div>
      <Header />
      <div
        className={"custom-scrollbar d-flex flex-row"}
        style={{ position: "fixed", height: "calc( 100vh - 100px)", width: "100vw", overflowY: "scroll"}}
      >
        {/* <Sidebar /> */}
        {/* <div className={"vr my-4 px-.5"}></div> */}
        <div className={"flex-grow-1 pt-2 mx-5"}>{children}</div>
      </div>
    </div>
  );
};

export default Layout;
