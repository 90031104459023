import './App.scss';
import {
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom';
import { Fragment, ReactElement, useEffect, useState } from 'react';
import Layout from './components/Layout';
import { IRoute, protectedRoutes } from './router/Routes';
import LandingHome from './pages/home/HomePage';

const App = (): ReactElement => {
  const navigate = useNavigate();

  const userToken = sessionStorage.getItem('user-token');

  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(!!userToken);

  const checkUserToken = (): void => {
    if (!userToken || userToken === 'undefined') {
      setIsLoggedIn(false);
      return navigate('/');
    }
    setIsLoggedIn(true);
  };

  useEffect(() => {
    checkUserToken();
  }, [JSON.stringify(userToken)]);

  return (
    <Fragment>
      {
        isLoggedIn
          ? <Layout>
            <Routes>
              {
                protectedRoutes.map((child: IRoute, idx: number) =>
                  <Route key={idx} path={child.path} element={child.element}/>
                )
              }
            </Routes>
          </Layout>
          :
          <Routes>
            <Route path={'/'} element={<LandingHome/>}/>
          </Routes>
      }
    </Fragment>
  );
};

export default App;
