import { Fragment } from "react";
import { PieChart, Pie, Sector, Cell, Label, Legend } from "recharts";

const RecruitingStatus = () => {
    //static data
  const data = [
    { name: "Suggested trials", value: 300 },
    { name: "Ongoing trials", value: 200 },
    { name: "Completed trials", value: 500 },
    { name: "Suggested trials", value: 300 },
    { name: "Suggested trials", value: 300 },
    { name: "Suggested trials", value: 300 },
    { name: "Suggested trials", value: 300 },
    { name: "Suggested trials", value: 300 },
    { name: "Suggested trials", value: 300 },
  ];
  const COLORS = [
    "rgba(0, 0, 0, 0.11)",
    "rgba(0, 0, 0, 0.66)",
    "rgba(0, 0, 0, 0.35",
  ];

  
  const CustomLegend = ({ payload }: any) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {payload.map((entry: any, index: number) => (
            <div key={`legend-${index}`} style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ width: '10px', height: '10px', backgroundColor: entry.color, marginRight: '5px' }}></div>
              <span>{entry.value}</span>
            </div>
          ))}
        </div>
      );
  };

  const PieChartComponent = ({ data, colors }: any) => (
    <PieChart width={400} height={200}>
      <Pie
        data={data}
        cx={65}
        cy={90}
        innerRadius={50}
        outerRadius={70}
        paddingAngle={-10}
        dataKey="value"
      >
        {data.map((entry: any, index: number) => (
          <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
        ))}
        <Label value="85%" position="center" fontSize={16} fill="#000000" />
      </Pie>
      <Legend content={<CustomLegend />} verticalAlign="middle" align="right" />
    </PieChart>
     
  );
  return (
    <Fragment>
      <div>
        <div className="divTitle mb-3 mt-3">
          <span style={{color:"#595959",fontSize:"16px", lineHeight:"19.36px", fontWeight:"700"}}>Recruiting status</span>
        </div>
        <div className="d-flex gap-3">
            <button className="btn fw-bold rounded buttonWidth" style={{border:"1px solid #E3E3E3",fontSize:"16px",lineHeight:"19.36px",fontWeight:"400"}}><span style={{color:"#4D4D4D",}}>This week</span></button>
            <button className="btn fw-bold rounded buttonWidth" style={{border:"1px solid #E3E3E3",fontSize:"16px",lineHeight:"19.36px",fontWeight:"400"}}><span style={{color:"#4D4D4D",}}>Lorem Ipsum</span></button>
            <button className="btn fw-bold rounded buttonWidth" style={{border:"1px solid #E3E3E3",fontSize:"16px",lineHeight:"19.36px",fontWeight:"400"}}><span style={{color:"#4D4D4D",}}>Lorem Ipsum</span></button>
        </div>
        <PieChartComponent data={data} colors={COLORS} />
      </div>
    </Fragment>
  );
};

export default RecruitingStatus;
