import React, { Fragment } from "react";

const boxItem = {
  items: [
    {
      head: "Study to Investigate Alternative Dosing Regimens of Belantamab Mafodotin",
      name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, Lorem ipsum dolor sit amet, consectetur adipiscing elit, adipiscing",
    },
    {
      head: "Study to Investigate Alternative Dosing Regimens of Belantamab Mafodotin",
      name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, Lorem ipsum dolor sit amet, consectetur adipiscing elit, adipiscing",
    },
    {
      head: "Study to Investigate Alternative Dosing Regimens of Belantamab Mafodotin",
      name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, Lorem ipsum dolor sit amet, consectetur adipiscing elit, adipiscing",
    },
  ],
};

const DashboardBlog = () => {
  return (
    <Fragment>
      <span className="font-size-16 lineHeight19 font-weight-700 headerColor mt-5">New clinical trials released this week</span>
      {boxItem.items.map((item, index) => (
        <div
        key={index}
        className="p-3 mb-3 bg-white borderRadius border border mt-4"
      >
        <div className="fw-bold">{item.head}</div>
        <div>{item.name} <span className="fw-bold pe-auto">Read More</span></div>
      </div>
      ))}
    </Fragment>
  );
};

export default DashboardBlog;
