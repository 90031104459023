import React, { useState } from "react";
import { ReactComponent as Location } from "../../assets/icon/landing_dashboard/Location.svg";

interface IBlog {
  data: any;
}

const Blog: React.FC<IBlog> = ({ data }) => {
  const [isReadMore, setIsReadMore] = useState<boolean>(false);
  return (
    <div
      className={"py-3 px-3 rounded-3 my-4 "}
      style={{
        backgroundColor: "rgba(248, 247, 249, 1)",
      }}
    >
      <a href={data?.link} target="_blank">
        <label
          className={"py-2 font-size-18"}
          style={{
            lineHeight: "27px",
            textAlign: "left",
            fontFamily: 'Poppins-Medium',
            cursor: "pointer"
          }}
        >
          {data?.id} - {data?.title}
        </label>
      </a>
      <p
        className="font-size-12 text-secondary"
        style={{
          lineHeight: "18px",
          letterSpacing: "0em",
          textAlign: "left",
          color: "rgba(136, 155, 172, 1)",
          fontFamily: "Poppins-Regular",
        }}
      >
        {!isReadMore ? (
          data?.brief_summary?.length > 100 ? (
            <div>
              {data?.brief_summary.slice(0, 100)}{" "}
              <span
                style={{
                  color: "#61B875",
                  fontFamily: "Poppins-Semi-Bold",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setIsReadMore(!isReadMore);
                }}
              >
                Read More
              </span>
            </div>
          ) : (
            data?.brief_summary
          )
        ) : (
          <div>
            {data?.brief_summary}
            <span
              style={{
                color: "#61B875",
                fontFamily: "Poppins-Semi-Bold",
                cursor: "pointer",
              }}
              onClick={() => {
                setIsReadMore(!isReadMore);
              }}
            >
              {" "}
              Read Less
            </span>
          </div>
        )}
      </p>
      <div
        style={{
          position: "relative",
          display: "flex",
          flexFlow: "wrap",
          gap: 3,
        }}
      >
        {data.conditions.map((condition: string, index: number) => {
          if (index > 3) {
            return;
          }
          return (
            <label
              key={condition}
              style={{ border: '1px solid #DDCCDD' }}
              className="margin-right-07 background-fffff mt-1 px-3 py-2  font-weight-500 rounded "
            >
              {condition}
            </label>
          );
        })}
        {/* {data.locations ? (
          data.locations.map((item: any) => {
            return (
              <div key={item.state}>
                <Location />
                <span
                  style={{
                    fontSize: "12px",
                    lineHeight: "18px",
                    fontWeight: "400",
                    color: "rgba(110, 114, 118, 1)",
                  }}
                >
                  {item?.state}, {item?.country}
                </span>
              </div>
            );
          })
        ) : (
          <></>
        )} */}
      </div>
    </div>
  );
};

export default Blog;
