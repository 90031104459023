import axios from "axios";

const chatURL = process.env?.REACT_APP_CHAT_API ?? "";
axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

export function getChatResponseFromAI(message: string) {
  return axios.post(chatURL, {
    human_request: message,
  });
}
