import React, { ReactElement, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { updateUser } from '../../api-services/apis';
import Back from '../../assets/icon/pataint/arrowback.svg';
import { useNavigate } from 'react-router-dom';

interface IProps {
  emailId: string;
  onSubmit: (formData: IForm) => void; // Update onSubmit to pass form data
}

interface IForm {
  email: string,
  firstName: string,
  lastName: string,
  contact: string,
  medicalRegistrationNumber: string,
}

const initForm: IForm = {
  email: '',
  firstName: '',
  lastName: '',
  contact: '',
  medicalRegistrationNumber: '',
};

const AccountSetupModal = (props: IProps): ReactElement => {
  const { emailId, onSubmit } = props;
  const [fields, setFields] = useState<IForm>({ ...initForm, email: emailId });
  const [emailError, setEmailError] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [contactError, setContactError] = useState(false);
  const phoneNumberRegex = /^[6789]\d{9}$/;
  const navigator = useNavigate();
  const validateFields = () => {
    setEmailError(fields.email.trim().length === 0);
    setFirstNameError(fields.firstName.trim().length === 0);
    setLastNameError(fields.lastName.trim().length === 0);
    setContactError(!/^[6789]\d{9}$/.test(fields.contact.trim()));
  };

  const handleSubmission = () => {
    validateFields();
    const isValid =
      fields.email &&
      fields.firstName &&
      fields.lastName &&
      fields.contact;

    if (isValid) {
      const id = localStorage.getItem('userID') ?? '';
      updateUser(id, fields.firstName, fields.lastName, emailId, fields.contact).then(res => {
        if (res.data) {
          onSubmit(fields); // Pass the form data to the onSubmit function
          sessionStorage.setItem('userName', `${fields.firstName} ${fields.lastName}`)
        }
      }).catch(err => {
        alert('Error while creating account!');
      })
    } else {
      // Handle invalid form - show error messages, prevent submission, etc.
    }
  };

  const handleFirstNameChange = (value: string) => {
    setFields({ ...fields, firstName: value });
    setFirstNameError(value.trim().length === 0);
  };

  const handleLastNameChange = (value: string) => {
    setFields({ ...fields, lastName: value });
    setLastNameError(value.trim().length === 0);
  };

  const handleContactChange = (value: string) => {
    const numericValue = value.replace(/\D/g, ''); // Remove non-numeric characters
    if (numericValue.length <= 10) {
      setFields({ ...fields, contact: numericValue });
      setContactError(numericValue.length !== 10);
    }
  };

  // const handleMedicalRegChange = (value: string) => {
  //   setFields({ ...fields, medicalRegistrationNumber: value });
  //   setMedicalRegError(value.trim().length === 0);
  // };

  return (
    <Modal size="lg" show={true}>
      <div className="background-left-bottom" />
      <div className="background-right-top" />
      <Modal.Body>
        <div className="row">
          <div className="cursor-pointer" style={{ marginTop: '10px', position: 'absolute', left: '10px', top: '-10px' }} onClick={() => {
            sessionStorage.setItem('user-token', '1');
            navigator('/clinical-trials');
          }}>
            <img src={Back} alt="back" />
            <span className="modal-back">Back</span>
          </div>
          <div className="col-md-12 text-center">
            <p className="modal-title mb-3">Lets finish setting up your account</p>

            <input
              type="text"
              placeholder="Enter your email address"
              className="mt-3 p-2 border-0 border-bottom no-outline w-100"
              disabled={true}
              value={fields.email}
            />
            {emailError ? (
              <span className="text-danger">Please enter email address</span>
            ) : null}

            <div className="row">
              <div className="col-md-6">
                <input
                  type="text"
                  placeholder="Enter your first name"
                  className="mt-3 p-2 border-0 border-bottom no-outline w-100"
                  onChange={(e) => handleFirstNameChange(e.target.value)}
                  value={fields.firstName}
                />
                {firstNameError ? (
                  <span className="text-danger">Please enter first name</span>
                ) : null}
              </div>

              <div className="col-md-6">
                <input
                  type="text"
                  placeholder="Enter your last name"
                  className="mt-3 p-2 border-0 border-bottom no-outline w-100"
                  onChange={(e) => handleLastNameChange(e.target.value)}
                  value={fields.lastName}
                />
                {lastNameError ? (
                  <span className="text-danger">Please enter last name</span>
                ) : null}
              </div>
            </div>

            <input
              type="text"
              placeholder="Enter your contact number"
              className="mt-3 p-2 border-0 border-bottom no-outline w-100"
              onChange={(e) => handleContactChange(e.target.value)}
              value={fields.contact}
            />
            {contactError ? (
              <span className="text-danger">Please enter contact number</span>
            ) : null}

            {/* <input
              type="text"
              placeholder="Enter your medical registration number"
              className="mt-3 p-2 border-0 border-bottom no-outline w-100"
              onChange={(e) => handleMedicalRegChange(e.target.value)}
              value={fields.medicalRegistrationNumber}
            />
            {medicalRegError ? (
              <span className="text-danger">Please enter registration number</span>
            ) : null} */}

            <button
              className="button btn-bg mt-5 border-0 fw-bold rounded d-block w-100"
              onClick={handleSubmission}
            >
              Finish
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AccountSetupModal;
