import React, { Fragment } from "react";
import DashboardGraph from "./DashboardGraph";
import DashboardBlog from "./DashboardBlog";
const Dashboard = () => {
  return (
    <Fragment>
      <div className="mx-4 custom-scrollbar paddingButtom" style={{ overflowY: "scroll", height: "100%", scrollbarWidth: "thin" }}>
        <div>
          <div className="dashboardTitile ">
            <span className="font-weight-bold fs-5 lh-1.25" style={{ fontSize: "18px", textAlign: "left", color: "rgba(0, 0, 0, 0.65)" }}>Clinical Trials by filters</span>
          </div>
          <div className="col-md-12 d-flex justify-content-center align-items-center">
            <div className="col-md-8">
              <DashboardGraph />
            </div>
            <div className="col-md-4 border-start">
              <div className="px-4">

              <DashboardBlog />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Dashboard;