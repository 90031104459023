import { Cell, Label, Legend, Pie, PieChart } from "recharts";
import React, { FC, useState } from "react";

interface IProps {
  title: string;
  data: any;
}


const Graph: FC<IProps> = ({ title, data }) => {

  const COLORS: string[] = [
    "rgba(97, 184, 117, 1)",
    "rgba(203, 207, 216, 1)",
    "rgba(110, 145, 177, 1)",
    "rgba(77, 196, 210, 1)",
    "rgba(153, 227, 236, 1)",
    "rgba(200, 229, 225, 1)",
    "rgba(81, 134, 187, 1)",
  ];

  // const TAGS: string[] = ["This month", "This year", "This week"];

  //intialize state
  const [activeTag, setActiveTag] = useState(0);

  const CustomLegend = ({ payload }: any) => {
    const halfLength = Math.ceil(payload.length / 2); // Divide the payload into two halves

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row", // Display items in a row
          position: "relative",
          bottom: "100px",
          left: "300px"
        }}
      >
        {/* First Column */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginRight: "20px", // Adjust spacing between columns
          }}
        >
          {payload.slice(0, halfLength).map((entry: any, index: number) => (
            <div
              key={`legend-${index}`}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px", // Adjust spacing between rows
              }}
            >
              <div
                style={{
                  width: "10px",
                  height: "10px",
                  backgroundColor: entry.color,
                  marginRight: "5px",
                  borderRadius: "50px",
                }}
              ></div>
              <span style={{ fontFamily: "Poppins-Regular", fontSize: "14px", }}>
                {entry.value}
              </span>
            </div>
          ))}
        </div>

        {/* Second Column */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {payload.slice(halfLength).map((entry: any, index: number) => (
            <div
              key={`legend-${index + halfLength}`}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px", // Adjust spacing between rows
              }}
            >
              <div
                style={{
                  width: "10px",
                  height: "10px",
                  backgroundColor: entry.color,
                  marginRight: "5px",
                  borderRadius: "50px",
                }}
              ></div>
              <span style={{ fontFamily: "Poppins-Regular", fontSize: "14px", }}>
                {entry.value}
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="graph-container me-4 mb-3">
      <div className="row mt-3">
        <div className="col-5 d-flex align-items-center ps-4">
          <span
            style={{
              fontSize: "24px",
              lineHeight: "52px",
              textAlign: "left",
              fontFamily: 'Poppins-Semi-Bold',
              color: 'rgba(0, 0, 0, 1)'
            }}
          >
            {title}
          </span>
        </div>
        <div className="col-7 d-flex ">
          {/*<div*/}
          {/*  style={{*/}
          {/*    borderRadius: "12px",*/}
          {/*    background: "#FFF",*/}
          {/*    padding: "10px",*/}
          {/*  }}*/}
          {/*>*/}
            {/*<div className="d-flex gap-3">*/}
            {/*  {TAGS.map((tag: string, idx: number) => (*/}
            {/*    <button*/}
            {/*      key={idx}*/}
            {/*      className="btn fw-bold rounded"*/}
            {/*      style={{*/}
            {/*        border: "1px solid #E3E3E3",*/}
            {/*      */}
            {/*        background:*/}
            {/*          activeTag === idx ? "rgba(232, 231, 231, 0.2)" : "#FFF",*/}
            {/*      }}*/}
            {/*      onClick={() => setActiveTag(idx)}*/}
            {/*    >*/}
            {/*      <span*/}
            {/*        style={{*/}
            {/*          fontSize: "14px",*/}
            {/*          lineHeight: "21px",*/}
            {/*          fontFamily:'Poppins-Semi-Bold',*/}
            {/*          color: activeTag === idx ? "#61B875" : "#6E7276",*/}
            {/*        }}*/}
            {/*      >*/}
            {/*        {tag}*/}
            {/*      </span>*/}
            {/*    </button>*/}
            {/*  ))}*/}
            {/*</div>*/}
          {/*</div>*/}
        </div>
      </div>

      <div className={"row px-5"}>
        <div className="col px-3">
          <PieChart width={400} height={200}>
            <Pie
              data={data}
              cx={65}
              cy={90}
              innerRadius={50}
              outerRadius={70}
              paddingAngle={-10}
              dataKey="value"
            >
              {data.map((entry: any, index: number) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
              {/* <Label
                value={`${data.reduce((max: any, obj: any) => (obj.value > max.value ? obj : max), data[0])?.value ?? 0}%`}
                position="center"
                fontSize={16}
                fill="#000000"
              /> */}
            </Pie>
            <Legend
              content={<CustomLegend />}
              verticalAlign="middle"
              align="right"
            />
          </PieChart>
        </div>
      </div>
    </div>
  );
};

export default Graph;
