import { FC, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as ClinicalTrialLogo } from "../assets/icon/clinicalTrial/clinicalTrailLogo.svg";

const Header: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleLogout = () => {
    setDropdownOpen(false);
    sessionStorage.removeItem("user-token");
    navigate("/");
  };
  const TAB = [
    {
      title: "Clinical Trials",
      path: "clinical-trials",
    },
    // {
    //   title: "Patients",
    //   path: "patients",
    // },
  ];

  useEffect(() => {
    // Access the pathname property of the location object to get the current route name
    const currentRoute = location.pathname.split("/")[1];
    const index = TAB.findIndex((tab) => tab.path === currentRoute);
    setActiveIndex(index);
  }, [location.pathname]);

  return (
    <div>
      <header style={{ height: "126x" }}>
        <div className="navbar-expand-lg navbar-light pt-3">
          <div className="row mx-2">
            <div className="col-4 d-flex">
              <div className="d-flex align-items-center" onClick={() => navigate("/clinical-trials")} style={{ cursor: "pointer" }}>
                <div className="logo">
                  <ClinicalTrialLogo />
                </div>
                <p className="clinical-logo m-0 ps-2">Ctrials</p>
                <span
                  className="font-size-18"
                  style={{
                    fontFamily: "Poppins-Semi-Bold",
                  }}
                >
                  .ai
                </span>
              </div>
            </div>
            <div className="col-4 d-flex align-items-center justify-content-center">
              {TAB.map((item, ind) => (
                <Link
                  key={item.path}
                  to={item.path}
                  onClick={() => setActiveIndex(ind)}
                  style={{
                    fontSize: "18px",
                    letterSpacing: "-1.1%",
                    marginLeft: "10px",
                    fontWeight: "500",
                    color:
                      activeIndex === ind ? "rgba(63, 63, 63, 1)" : "#A0A0A0",
                    borderBottom:
                      activeIndex === ind
                        ? "3px solid #4DC4D2"
                        : "none",
                    paddingBottom: "7px",
                    paddingRight: "15px",
                    paddingLeft: "15px",
                    textDecoration: "none",
                  }}
                >
                  {item.title}
                </Link>
              ))}
            </div>
            <div className="col-4" style={{ position: 'relative' }}>
              <div
                className="d-flex align-items-center justify-content-end"
                onKeyDown={handleToggle}
                onClick={handleToggle}
              >
                <div className="prifile-logo">
                  <span>{(sessionStorage.getItem('userName') ?? 'Unknown').split('')[0]}</span>
                </div>
                <div className="Title mt-2">
                  <h6
                    className="font-size-16 Poppins-Medium"
                    style={{
                      letterSpacing: "-1.1%",
                      marginLeft: "10px",
                    }}
                  >
                    {sessionStorage.getItem('userName') ?? 'Unknown'}
                  </h6>
                </div>
              </div>

              <div
                className={`dropdown-menu ${dropdownOpen ? "show" : ""}`}
                style={{
                  borderRadius: "20px",
                  border: "1px solid #EFEFEF",
                  boxShadow: "0px 4px 4px 0px #00000040",
                  position: "absolute",
                  right: "7px",
                  top: "3px",
                  padding: '16px'
                }}
              >
                <div
                  className="d-flex align-items-center justify-content-end"
                  onClick={handleToggle}
                >
                  <div className="prifile-logo">
                    <span>{(sessionStorage.getItem('userName') ?? 'Unknown').split('')[0]}</span>
                  </div>
                  <div className="Title mt-2">
                    <h6
                      className="font-size-16 Poppins-Medium"
                      style={{
                        letterSpacing: "-1.1%",
                        marginLeft: "10px",
                      }}
                    >
                      {sessionStorage.getItem('userName')}
                    </h6>
                  </div>
                </div>
                <div className="dropdown-divider"></div>
                <span
                  className="font-size-14 ps-3 cursor-pointer"
                  onClick={handleLogout}
                >
                  Logout
                </span>
              </div>
            </div>
          </div>
          <hr className="mt-2 mb-3" />
        </div>
      </header>
    </div>
  );
};

export default Header;
