import React, { Fragment } from "react";
import { ReactComponent as FacebookLogo } from "../../assets/icon/landing_dashboard/facebooklogo.svg";
import { ReactComponent as TwiterLogo } from "../../assets/icon/landing_dashboard/TwiterLogo.svg";
import { ReactComponent as LinkdhiLogo } from "../../assets/icon/landing_dashboard/linkdhinLogo.svg";
import { ReactComponent as InstagramLogo } from "../../assets/icon/landing_dashboard/instagramLogo.svg";
import { ReactComponent as ClinicalTrialLogo } from "../../assets/icon/clinicalTrial/clinicalTrailLogo.svg";

const Footer = () => {
  return (
    <Fragment>
      <div className="footer">
        <div className="row ">
          <div className="d-flex justify-content-center gap-3">
            <span>
              <ClinicalTrialLogo />
              <span className="clinical-logo m-0 ps-2">Ctrials</span>
            </span>
            <span>|</span>
            <FacebookLogo style={{ marginTop: "5px" }} />
            <TwiterLogo />
            <LinkdhiLogo />
            <InstagramLogo />
          </div>

          <div className="d-flex justify-content-center gap-3 mt-2">
            <span>Home</span>
            <span>|</span>
            <span>Service</span>
            <span>|</span>
            <span>Resource</span>
            <span>|</span>
            <span>Contact</span>
            <span>|</span>
            <span>about</span>
          </div>
          <div className="d-flex justify-content-center gap-3 mt-2">
            <span>© 2023 Ctrials.ai </span>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Footer;
