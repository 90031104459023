import { Navigate } from 'react-router-dom';

import { ReactComponentElement } from 'react';
import Dashboard from '../pages/dashboard';
import PatientScreen from '../pages/patient/PatientScreen';


import ClinicalTrialScreen from '../pages/clinicalTrial/ClinicalTrialScreen';
import AddPatientScreen from '../pages/patient/AddPatientScreen';
import PatientDetailsScreen from '../pages/patient/patient-detail/PatientDetailsScreen';

export interface IRoute {
  path: string;
  element: ReactComponentElement<any>;
}

export const protectedRoutes: IRoute[] = [
  {
    path: '/dashboard',
    element: <Dashboard />,
  },
  {
    path: '/clinical-trials',
    element: <ClinicalTrialScreen />
  },
  {
    path: '/clinical-trials/:id',
    element: <ClinicalTrialScreen />
  },
  // {
  //   path: '/patients',
  //   element: <PatientScreen />
  // },
  // {
  //   path: '/patients/:id/details',
  //   element: <PatientDetailsScreen />
  // },
  // {
  //   path: '/patients/uploadReport/:name/:age/:gender/:tumorType/:tumorSubType',
  //   element: <AddPatientScreen />
  // },
  {
    path: '/',
    element: <Navigate to="/clinical-trials" replace />
  },
];
