import React, { useEffect, useState } from "react";
import { ReactComponent as ActiveCircle } from "../../assets/icon/clinicalTrial/activecircle.svg";
import { ReactComponent as NotActiveCircle } from "../../assets/icon/clinicalTrial/notactivecircle.svg";
import Map from "../../assets/image/map.png";
import Graph from "../../components/dashboard/Graph";
import { getGraphData } from "../../utils/graph";
import { ReactComponent as Expand } from '../../assets/icon/clinicalTrial/expandIcon.svg';
import { ReactComponent as ExpandClose } from '../../assets/icon/clinicalTrial/expandMore.svg';
import MapBoxMap from "../../components/MapBoxMap";
import { extractCommonLocation } from "../../utils/utility";
interface IndividualClinicalResult {
  id: string;
  link: string;
  interventions: [];
  phase: [];
  location: [];
  recruitingStatus: string;
  title: string;
  conditions: [];
}
interface ICheckboxState {
  [key: string]: string[];
}

interface MyClinicalTrial {
  checkboxState: ICheckboxState;
  clinicalTrialResult: any;
  currentQuestion: any;
}

const ClinicalTrial: React.FC<MyClinicalTrial> = ({ checkboxState, clinicalTrialResult, currentQuestion }) => {
  const [activeTab, setActiveTab] = useState("listing"); // State to manage active tab
  const [filteredClinicalTrialResult, setFilteredClinicalTrialResult] = useState<any[]>([]);
  const [states, setStates] = useState<any[]>([]);
  const [selectedTrials, setSelectedTrials] = useState<any[]>([]);
  useEffect(() => {
    setFilteredClinicalTrialResult(clinicalTrialResult);
  }, [clinicalTrialResult])

  useEffect(() => {
    const data = extractCommonLocation(filteredClinicalTrialResult).countryStateMapper;
    // @ts-ignore
    if (checkboxState?.location?.country && checkboxState?.location?.country !== 'All') {
      // @ts-ignore
      setStates(data[checkboxState?.location?.country]);
    } else {
      setStates(extractCommonLocation(filteredClinicalTrialResult).states);
    }

  }, [filteredClinicalTrialResult, checkboxState]);


  useEffect(() => {
    if (
      Object.keys(checkboxState).length == 0 ||
      Object.values(checkboxState).every((value: number | any) => value == 0)
    ) {
      setFilteredClinicalTrialResult(clinicalTrialResult);
      return;
    }
    const { interventions, recruitingStatus, phase, location } = checkboxState;
    let finalData: any[] = [];
    let currentData = JSON.parse(JSON.stringify(clinicalTrialResult));

    if (interventions && interventions.length > 0) {
      let counter = 0;
      interventions.forEach((int: string) => {
        currentData.forEach((item: IndividualClinicalResult) => {
          if (item['interventions'].join('').toLowerCase().includes((int ?? '').toLowerCase())) {
            counter++;
            finalData.push(item);
          }
        });
      });
      if (counter == 0) {
        finalData = [];
      }
    } else {
      finalData = currentData;
    }
    let secondFinalData: any[] = [];
    if (recruitingStatus && recruitingStatus.length > 0) {
      let counter = 0;
      recruitingStatus.forEach((rec: string) => {
        (finalData.length > 0 ? finalData : currentData).forEach((item: IndividualClinicalResult) => {
          if (item['recruitingStatus'].toLowerCase() === (rec ?? '').toLowerCase()) {
            counter++;
            secondFinalData.push(item);
          }
        });
      });
      if (counter == 0) {
        secondFinalData = [];
      }
    } else {
      secondFinalData = finalData
    }


    let thirdFinalData: any[] = [];
    if (phase && phase.length > 0) {
      let counter = 0;
      phase.forEach((phs: string) => {
        (finalData.length > 0 ? finalData : secondFinalData).forEach((item: IndividualClinicalResult) => {
          if (item['phase'].join('').toLowerCase().includes((phs ?? '').toLowerCase())) {
            counter++;
            thirdFinalData.push(item);
          }
        });
      });
      if (counter == 0) {
        thirdFinalData = [];
      }
    } else {
      thirdFinalData = secondFinalData;
    }
    let fourthFinalData: any[] = [];

    if (location) {
      // @ts-ignore
      if (location?.country == 'All' || location.state == 'All') {
        setFilteredClinicalTrialResult((secondFinalData.length > 0 ? secondFinalData : thirdFinalData));
        return;
      }
      (finalData.length > 0 ? finalData : thirdFinalData).forEach((item: IndividualClinicalResult) => {
        // @ts-ignore
        for (let currentItem of (item?.locations ?? [])) {
          // @ts-ignore
          if (currentItem && (currentItem.country === location?.country) || currentItem && (currentItem.state === location?.state)) {
            fourthFinalData.push(item);
          }
        }
      });
    } else {
      fourthFinalData = thirdFinalData;
    }
    const uniqueData = fourthFinalData.filter((value: any, index: number, self: any) => {
      return self.indexOf(value) === index;
    });
    setFilteredClinicalTrialResult(uniqueData);
  }, [checkboxState]);

  // useEffect(() => {
  //   if (
  //     Object.keys(checkboxState).length == 0 ||
  //     Object.values(checkboxState).every((value: number | any) => value == 0)
  //   ) {
  //     setFilteredClinicalTrialResult(clinicalTrialResult);
  //     return;
  //   }
  //   let currentData = JSON.parse(JSON.stringify(filteredClinicalTrialResult));
  //   // for (let inputKey in checkboxState) {
  //   //   if (checkboxState[inputKey].length == 0){
  //   //      // unfilter the whole things out of it
  //   //   }

  //   // }
  //   let finalData: any[] = [];
  //   for (let inputKey in checkboxState) {
  //     let data: any = [];
  //     currentData.forEach((item: IndividualClinicalResult) => {
  //       if (inputKey === 'location') {
  //         // @ts-ignore
  //         if (checkboxState?.['location']?.country == 'All' || checkboxState['location'].state == 'All') {
  //           setFilteredClinicalTrialResult(clinicalTrialResult);
  //           return;
  //         }
  //         // @ts-ignore
  //         for (let currentItem of (item?.locations ?? [])) {
  //           // @ts-ignore
  //           if (currentItem && (currentItem.country === checkboxState?.['location']?.country) || currentItem && (currentItem.state === checkboxState?.['location']?.state)) {
  //             data.push(item);
  //           }
  //         }
  //         return;
  //       }
  //       const innerData = checkboxState?.[inputKey]?.filter((check: string) => {
  //         if (check !== undefined) {
  //           const key = inputKey as keyof IndividualClinicalResult;
  //           let itemValue: any = item?.[key];
  //           if (Array.isArray(itemValue) && itemValue.length) {
  //             itemValue = itemValue.join().toLowerCase().trim().split(",");
  //             return itemValue?.includes(check.toLowerCase());
  //           } else {
  //             return itemValue?.toLowerCase() === check.toLowerCase();
  //           }
  //         }
  //         return false;
  //       });
  //       if (innerData && innerData.length > 0) {
  //         data.push(item);
  //       }
  //     });
  //     const uniqueData: any = {};

  //     for (let item of ) {
  //       if (!uniqueData[item.id]) {
  //         uniqueData[item.id] = item;
  //       }
  //     }
  //     currentData = Object.values(uniqueData);
  //     finalData = Object.values(uniqueData);

  //   }
  //   // const uniqueData = finalData.filter((value: any, index: number, self: any) => {
  //   //   return self.indexOf(value) === index;
  //   // });
  //   setFilteredClinicalTrialResult(finalData);
  //   return () => { }
  // }, [checkboxState]);

  const handleTabChange = (tab: any) => {
    setActiveTab(tab);
  };


  return (
    <div >
      <div
        className="container px-4 custom-scrollbar clinic-dashboard-scroll"
      >
        <p
          style={{
            fontSize: "12px",
            color: '#6E7276',
            fontFamily: "Poppins-Medium",
          }}
        >
          Showing clinical trials for <i>"{currentQuestion}"</i>
        </p>
        <div className="d-flex justify-content-between gap-3 mb-3 mt-2">
          <div className="d-flex justify-content-center gap-1 shadow-sm py-1 px-1 rounded-2">
            <button
              onClick={() => handleTabChange("listing")}
              className={`mt-1 p-2 border-0 font-weight-500 rounded buttonWidth  ${activeTab === "listing" ? "activeTab" : ""
                }`}
              style={{
                backgroundColor: activeTab === "listing" ? "#D9D9D9" : "#FFFFFF",
                color: activeTab === "listing" ? "#61B875" : "#8A8484",
                border:
                  activeTab === "listing"
                    ? "1px solid #D9D9D9"
                    : "1px solid #8A8484",
              }}
            >
              Listing ({filteredClinicalTrialResult?.length ?? 0})
            </button>
            <button
              onClick={() => handleTabChange("dashboard")}
              className={`btn mt-1 p-2 border-0 font-weight-500 rounded buttonWidth ${activeTab === "dashboard" ? "activeTab" : ""
                }`}
              style={{
                backgroundColor:
                  activeTab === "dashboard" ? "#D9D9D9" : "#FFFFFF",
                color: activeTab === "dashboard" ? "#61B875" : "#8A8484",
                border:
                  activeTab === "dashboard"
                    ? "1px solid #D9D9D9"
                    : "1px solid #8A8484",
              }}
            >
              Dashboard
            </button>
          </div>
          <button
            onClick={() => {
              setSelectedTrials([]);
              alert('List exported successfully!')
            }}
            disabled={selectedTrials.length <= 0}
            className={`mt-1 p-2 border-0 font-weight-500 rounded buttonWidth 
              }`}
            style={{
              background: "#D9D9D9",
              border:
                activeTab === "listing"
                  ? "1px solid #D9D9D9"
                  : "1px solid #8A8484",
            }}
          >
            Export
          </button>
        </div>
        {activeTab === "listing" && (
          <div>
            {filteredClinicalTrialResult.length ? (
              filteredClinicalTrialResult.map(
                (item: IndividualClinicalResult) => (
                  <div
                    key={item.id}
                    className="height-auto bg-gray-light-1A mt-3 rounded border border-gray p-4 card-body"
                  >
                    <div className="d-flex align-items-center">
                      <div className="box">
                        <div className="d-flex gap-3 custom-checkbox">
                          <input
                            type="checkbox"
                            className="form-check-input mt-2"
                            checked={selectedTrials.includes(item.id)}
                            onClick={() => { setSelectedTrials([...selectedTrials, item.id]) }}
                          />
                          {/*<div*/}
                          {/*  className="rounded-circle height-12 mt-3 background-D9D9D91A"*/}
                          {/*></div>*/}
                          <label
                            className="font-size-18 mt-2 color-gray card-title"
                          >
                            {
                              item.recruitingStatus.toLowerCase().startsWith('active') ? <ActiveCircle className={"me-2"} /> : <NotActiveCircle className={"me-2"} />
                            }
                            {item.recruitingStatus} - {item.id}
                          </label>
                        </div>
                        <a
                          href={item?.link}
                          target="_blank"
                          className="font-size-18 font-poppins-medium card-header d-block pt-2"
                          style={{ cursor: "pointer", textDecoration: "none" }}
                        >
                          {item.title}
                        </a>
                        <hr />
                        <label
                          className="font-size-18 color-gray card-title pb-2"
                        >
                          Conditions
                        </label>
                        <br />
                        {item.conditions.map((condition, index) => {
                          if (index > 5) {
                            return;
                          }
                          return (
                            <label
                              key={condition}
                              className="margin-right-07 background-D9D9D9 mt-1 px-3 py-2 border-0 font-weight-500 rounded "
                            >
                              {condition}
                            </label>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )
              )
            ) : (
              <></>
            )}
          </div>
        )}
        {activeTab === "dashboard" && (
          <div>
            {getGraphData(filteredClinicalTrialResult ?? [], checkboxState).map((data: any, idx: number) => (
              <Graph title={data?.title} data={data?.data} key={idx} />
            ))}
            <div
              style={{
                width: '100%',
                height: "300px",
                position: 'relative'
              }}
            >
              <MapBoxMap id="mapbox-inner" locations={states} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ClinicalTrial;
