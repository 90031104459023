import { Fragment } from "react";
import Interventions from "./Interventions";
import RecruitingStatus from "./RecruitingStatus";

const DashboardGraph = () =>{
    return(
        <Fragment>
            <div>
                <Interventions />
                <RecruitingStatus />
            </div>
        </Fragment>
    )
}

export default DashboardGraph;